@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  h1 {
    @apply font-light;
    @apply text-4xl;
    @apply mb-2;
  }
  h2 {
    @apply font-light;
    @apply text-3xl;
    @apply mb-2;
  }
  h3 {
    @apply font-light;
    @apply text-2xl;
    @apply mb-2;
  }
  p {
    @apply font-light;
    @apply mb-2;
  }
}

/* Fix for invisible checkbox */
#gdprConsent {
  opacity: 1 !important;
  position: relative !important;
}
